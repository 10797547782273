// Loads the AWS WAF JS API Script dynamically
export function loadScript () {
  if (document.getElementById('AwsWAFScript')) return

  const AwsWafScript = document.createElement('script')
  AwsWafScript.id = 'AwsWAFScript'
  AwsWafScript.async = false
  AwsWafScript.src = 'https://0d713db39342.us-west-2.captcha-sdk.awswaf.com/0d713db39342/jsapi.js'
  document.head.appendChild(AwsWafScript)
}
