import styled from "styled-components";

export const MainDiv = styled.div`
  margin: 4% 8%;
  padding: 0;
  color: var(--font-color);
`;

export const Container = styled.div`
  padding: 40px 0px 20px 70px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  border: 2px solid #d7d7d7;
  box-shadow: 5px 5px 1px 4px #d7d7d7;

  @media (max-width: 1440px) {
    padding: 60px 0px 20px 60px;
  }

  @media (max-width: 1024px) {
    padding: 2% 0% 2% 0%;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    border: 0;
    box-shadow: none;
  }
`;

export const FormContainer = styled.div`
  padding: 5%;
`;

export const MapContainer = styled.div`
  margin: 0;
  border-radius: 10px;
  margin-right: 80px;
  @media (max-width: 1024px) {
    margin-right: 35px;
  }
  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const AddressContainer = styled.div`
  margin: 0% 40% 0% 10%;
  margin-right: 40%;
  color:var(--font-color);
  @media (max-width: 1440px) {
    margin: 10px 0px 0px 60px;
    margin-right: 90px;
  }

  @media (max-width: 1024px) {
    margin: 10px 10px 10px 75px;
    margin-right: 20px;
  }
  @media (max-width: 425px) {
    margin: 10px 10px 10px 50px;
  }
`;
export const Header = styled.h1`
  font: normal normal bold 57px/65px Montserrat;
  color:var(--font-color);
  @media (max-width: 1440px) {
    font: normal normal bold 38px/45px Montserrat;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.h4`
  font: normal normal bold 24px/56px Montserrat;
  color:var(--font-color);
  @media (max-width: 1440px) {
    font: normal normal bold 18px/40px Montserrat;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 45px;
  margin-top: -5px;
  position: absolute;
  @media (max-width: 768px) {
    width: 35px;
    margin-top: 5px;
  }
`;

export const Textbox = styled.input`
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #bfbfbf;
  width: 100%;
  @media (max-width: 425px) {
    padding: 5px;
  }
`;

export const TextArea = styled.textarea`
  margin: 10px 0;
  height: 150px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #bfbfbf;
  padding: 10px;
  width: 100%;
`;

export const SendButton = styled.input`
  background-color: #00ae4f;
  padding: 3% 0;
  margin: 10px 0;
  color: #fff;
  font-size: 26px;
  border: 0;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 425px) {
    padding: 2% 50%;
    font-size: 20px;
  }
`;

export const IconAndAddressContainer = styled.div`
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
  position: relative;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const HeaderMobile = styled.h1`
  font: normal normal bold 44px/65px Montserrat;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  @media (max-width: 425px) {
    font: normal normal bold 24px/30px Montserrat;
  }
`;

export const ErrorMessage = styled.div`
  color:red;
  display:none;
  &.show{
    display:block;
  }
  &.hide{
    display:none;
  }
`;

export const SuccessMessage = styled.div`
color:#00AE4F;
`;

export const ModelBox = styled.div`
display:flex;
justify-content:center;
align-item:center;
`;

export const ModelContent = styled.div`
padding:20px;
position: fixed;
top: 20%;
left: 10%;
margin: auto;
width: 80%;
height: 100%;
height: auto;
z-index:2;
background:white;
box-shadow: 0px 1px 20px 7px #000;
border-radius:10px;
`;

export const CloseModel= styled.span`
color: #aaaaaa;
float: right;
top: 0;
margin-right:10px;
font-size: 28px;
font-weight: bold;

&:hover{ cursor: pointer; }
`;