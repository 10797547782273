import React, { useEffect, useState } from "react";
import * as Styled from "./contactUsStyles";
import Map from "./map";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import ContentfulAssets from "../../hooks/useContentfulAssets";
import { Col, Row } from "react-bootstrap";
import { postFormData } from "../../constants/fetchApi";
import { addTrailingSlash, isExternalUrlhref } from "../../utils";
import { loadScript } from '../../aws-waf-captcha/util';

const ContactUsComponent = (sectionData) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMesssage] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  
  const UserData = {
    name: name,
    email: email,
    message: message,
  };
  
  useEffect(()=>{
    loadScript();
  }
  );
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name?.trim() !== "" &&
      email?.trim() !== "" &&
      message?.trim() !== "" &&
      isEmail(email) &&
      hasNoSpecialCharAtStart(name)
    ) {
      setErrorMesssage(false);
      // postFormData(UserData)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       window.location.assign(
      //         isExternalUrlhref("/thank-you/")
      //           ? "/thank-you/"
      //           : addTrailingSlash(
      //               process.env.GATSBY_DOMAIN_URL + "/thank-you/"
      //             )
      //       );
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setOpenModel(!openModel);
        
        const container = document.getElementById("captcha-container");
        window.AwsWafCaptcha.renderCaptcha(container, {
          apiKey:
            "OehL0eFPFNJ8X1DzcqmO17KpS8E8KLv1go1hL7GcF+wRAgKkPW+AfcZ800tKOIFkOwJnCa/UD+LUGDsNuE2RGcPQmngKvQpvrVYknD4O26yWvVzroianfaiE2rL0385iG/5utviENkKlg942G9jpeC9jaqCWoCRQ2LUOu62CFX+ddeCngcrBS16/+S+fzw+yQZ/HuOVg3NataPs97R1SXkj6CQrhy7ShnIIie4/ROEUtOo+m21C1ZCpy2sttxHXCZIb23Ip+v54KjBuaAgDdpaFBwvvabG9FcZkBmkxIlYM5n7CG6sW1m1oGVVOPN0qc0G71V6IzwOhCwa8yyZ7+JpbKiF9GqJYhX5ckrWaS3P/u/KjUUY0DoxqZQP91TWiJQNgxah+BrqERQ1ocTUziGZcyVU8tofVPjNysrGU0hSbGGsMRIaTseQOzy+/P222HYnWjNU6nPG/4LQdDMwzXK8zsxOBI4up7url8PQcSjAjzPrFD8Y/4Yrtv5EOaD7fQKtNr9cyMrjEvtanV+X9Xe4/FmKmPhG9NJOAusmdZy4L/pvURRFHvVm/8x8LhHOEMTD0+FhhNPGURiTHLlyFGIvPSQIw+X/NpE+nFE+rdg9rstyB5vfMGuAJlhT4dn2IUbys66/VYGhmG4WwNayDrr5E0pLKDUoqN3+iYgptp2hQ=_0_1",
          onSuccess: () => {
            window.AwsWafIntegration.fetch(
              "https://1e7d45zsr8.execute-api.us-west-2.amazonaws.com/dev/development",
              {
                method: "POST",
                headers: { 
                'Content-Type': 'application/json',
                'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
                'X-Frame-Options': 'SAMEORIGIN',
                'Content-Security-Policy': "default-src 'self'"
              },
                body: JSON.stringify(UserData),
              }
            ).then((response) => response.json())
          .then((data) => {
                  if (data?.SendMessageResponse?.ResponseMetadata?.RequestId != undefined) { 
                    window.location.assign(
                      isExternalUrlhref("/thank-you/")
                        ? "/thank-you/"
                        : addTrailingSlash(
                            process.env.GATSBY_DOMAIN_URL + "/thank-you/"
                          )
                    );
                  }else{
                    setOpenModel(false);
                  }
          })
          },
          
        });
      setName("");
      setEmail("");
      setMessage("");
    }else{
          if (!hasNoSpecialCharAtStart(name) ) {
            setErrorMesssage("Name should not start with number or special character.");
          }
          if (!isEmail(email) ) {
            setErrorMesssage("Please enter a valid email address.");
          }
    }
  };

  const isEmail = (val) => {
    let regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  };

  const hasNoSpecialCharAtStart = (val) => {
    var hasNumber = /^(?![^\p{L}\p{M}])/u;
    return hasNumber.test(val);
  };

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = ContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.Image
              src={asset?.node?.file?.url}
              alt={asset?.node?.title}
            />
          );
        }
      },

      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.AddressContainer>
          <span style={{ wordBreak: "break-word" }}>{children}</span>
        </Styled.AddressContainer>
      ),
    },
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  };
  
  

  return (
    <Styled.MainDiv>
      {/* <button onClick={()=>{handleClick()}}>Click</button> */}
      <Styled.ModelBox style={{display:openModel ?'flex':'none'}}> 
              <Styled.ModelContent >
              <Styled.CloseModel onClick={()=>{setOpenModel(!Styled.ModelBox)}}>&times;</Styled.CloseModel>
              <div id='captcha-container' style={{display:openModel ?'block':'none'}}></div>
              </Styled.ModelContent>
      </Styled.ModelBox>
      
      <Styled.HeaderMobile>
        {sectionData?.sectionData?.title}
      </Styled.HeaderMobile>
      <Styled.Container>
        <Styled.FormContainer>
          <Styled.Header>{sectionData?.sectionData?.title}</Styled.Header>
          <Styled.Title>{sectionData?.sectionData?.header}</Styled.Title>
          {sectionData?.sectionData?.list?.map((item, index) => {
            return (
              <Styled.IconAndAddressContainer key={index}>
                {documentToReactComponents(
                  JSON.parse(item?.contentDetails?.raw),
                  optionsMainStyle
                )}
              </Styled.IconAndAddressContainer>
            );
          })}

          <form onSubmit={handleSubmit.bind(this)}>
            
            <Row>
              <Col lg="5" md="12" sm="12">
                <Styled.Textbox
                  value={name}
                  type="text"
                  required
                  placeholder="Name"
                  onChange={(e) => {
                    setName(e?.target?.value);
                    if(e?.target?.value?.trim().length===0){
                      setErrorMesssage("Name should not be empty.");
                    }else{
                      setErrorMesssage(false);
                      if (!hasNoSpecialCharAtStart(e?.target?.value) ) {
                        setErrorMesssage(
                          "Name should not start with number or special character."
                        );
                      }else{
                        setErrorMesssage(false);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    setName(e?.target?.value?.trim());
                  }}
                ></Styled.Textbox>
              </Col>
              <Col lg="5" md="12" sm="12">
                <Styled.Textbox
                  value={email}
                  type="email"
                  required
                  placeholder="Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if(e?.target?.value?.trim().length===0){
                      setErrorMesssage("Email address should not be empty.");
                    }else{
                      setErrorMesssage(false);
                      if (!isEmail(e?.target?.value) ) {
                        setErrorMesssage("Please enter a valid email address.");
                      }else{
                        setErrorMesssage(false);
                      }
                    } 
                  }}
                  onBlur={(e) => {
                    setEmail(e?.target?.value?.trim());
                  }}
                ></Styled.Textbox>
              </Col>
            </Row>
            <Row>
              <Col lg="10" md="12" sm="6">
                <Styled.TextArea
                  value={message}
                  placeholder="Write your message here"
                  required
                  onChange={(e) => {
                    setMessage(e.target.value);
                    if(e?.target?.value?.trim().length===0){
                      setErrorMesssage("Message should not be empty.");
                    }else{
                      setErrorMesssage(false);
                    }
                  }}
                ></Styled.TextArea>
              </Col>
            </Row>
            {errorMessage && (
              <Styled.ErrorMessage
                className={
                  errorMessage ||
                  !isEmail(email) ||
                  !hasNoSpecialCharAtStart(name)
                    ? "show"
                    : "hide"
                }
              >
                {errorMessage}
              </Styled.ErrorMessage>
            )}
            <Row>
              <Col lg="10" md="12" sm="6">
                <Styled.SendButton
                  type="submit"
                  value="Send"
                  disabled={errorMessage ? true : false}
                ></Styled.SendButton>
              </Col>
            </Row>
          </form>
        </Styled.FormContainer>
        <Styled.MapContainer>
          <Map />
        </Styled.MapContainer>
      </Styled.Container>
    </Styled.MainDiv>
  );
};

export default ContactUsComponent;
